import React, { Component, useEffect, useState } from 'react';

import { observer } from 'mobx-react';

import { ChatListType } from '~/types/chat.types';

import * as chatApi from '~/api/chatApi';

import chatStore from '~/stores/chatStore';

import FlashOnIcon from '@material-ui/icons/FlashOn';
import SendIcon from '@material-ui/icons/Send';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';

import Button from '~ui/Button';
import TextInput from '~ui/TextInput';

import ListError from '~/components/ListError';

import ItemErrors from '../Items/ItemErrors';

const useStyles = makeStyles(() => ({
    root: {
        maxWidth: 795
    }
}));

type ChatSendBoxProps = {
    message: string;
    onSend: () => void;
    onChange: (message: string) => void;
    disabled?: boolean;
    label?: string;
    chatType?: ChatListType;
    chat_id?: number;
};

export const ChatSendBox = ({ chatType, chat_id, message, onSend, onChange, disabled, label = 'Сообщение' }: ChatSendBoxProps) => {
    const classes = useStyles();

    const handleEditMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
    };

    const enableAI = chatType && chat_id;

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [done, setDone] = useState(false);

    const generateAnswer = async () => {
        if (!chatType || !chat_id) {
            return;
        }
        setLoading(true);
        setErrors([]);
        try {
            const message = await chatApi.computeAiAdsChatAnswer(chatType, chat_id);
            onChange(message);
            setDone(true);
        } catch (errors) {
            setErrors(errors);
        } finally {
            setLoading(false);
        }
    };

    return (
        <TextInput
            className={classes.root}
            label={label}
            multiline
            rowsMax={8}
            rows={1}
            margin="normal"
            value={message}
            onChange={handleEditMessage}
            extraLabel={
                <>
                    {errors.length > 0 && <ListError errors={errors} />}
                    {enableAI && (
                        <Button
                            disabled={done}
                            loading={loading}
                            onClick={generateAnswer}
                            icon={<FlashOnIcon />}
                            color="secondary"
                            tooltip="Сгенерировать ответ от ИИ"
                        ></Button>
                    )}
                    <IconButton onClick={onSend} disabled={disabled}>
                        <Tooltip title="Отправить" placement="top">
                            <SendIcon color={!disabled ? 'primary' : 'disabled'} />
                        </Tooltip>
                    </IconButton>
                </>
            }
        />
    );
};

type ChatSendAreaProps = {
    item_id: number;
    chatType: ChatListType;
};

const ChatSendArea = (props: ChatSendAreaProps) => {
    const [message, setMessage] = useState('');

    useEffect(() => {
        setMessage('');
    }, [props.item_id, props.chatType]);

    const handleSend = () => {
        chatStore.sendMessage(props.item_id, props.chatType, message);
        setMessage('');
    };

    const { item, errors } = chatStore.getItem(props.item_id);

    if (!item) {
        return null;
    }

    return (
        <div className="crm-Chat__footer">
            <ItemErrors errors={errors} />
            <ChatSendBox
                chatType={props.chatType}
                chat_id={props.item_id}
                message={message}
                onSend={handleSend}
                onChange={setMessage}
                disabled={!message.length}
            />
        </div>
    );
};

export default observer(ChatSendArea);
